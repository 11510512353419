// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-container {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    /* width: 100%; */
    /* background-color: #fff; */
    padding: 10px 0;
    /* border-top: 1px solid #ccc; */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
.pagination {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 10px;
  }
  
  .page-link {
    padding: 5px 10px;
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    color: gray;
  }
  
  .page-link:hover {
    background-color: #c6ccd0;
    color: black;
  }
  
  .active .page-link {
    background-color: #c6ccd0;
    color: black;
    border-color: none;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Candidates/Candidates.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,iBAAiB;IACjB,4BAA4B;IAC5B,eAAe;IACf,gCAAgC;IAChC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;EACb;AACF;IACI,aAAa;IACb,gBAAgB;IAChB,uBAAuB;IACvB,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;EACpB","sourcesContent":[".pagination-container {\n    position: fixed;\n    bottom: 0;\n    left: 50%;\n    transform: translateX(-50%);\n    /* width: 100%; */\n    /* background-color: #fff; */\n    padding: 10px 0;\n    /* border-top: 1px solid #ccc; */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 10;\n  }\n.pagination {\n    display: flex;\n    list-style: none;\n    justify-content: center;\n    padding: 10px;\n  }\n  \n  .page-link {\n    padding: 5px 10px;\n    margin: 0 5px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    cursor: pointer;\n    color: gray;\n  }\n  \n  .page-link:hover {\n    background-color: #c6ccd0;\n    color: black;\n  }\n  \n  .active .page-link {\n    background-color: #c6ccd0;\n    color: black;\n    border-color: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
