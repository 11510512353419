import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const DataGridTable = ({
  rows,
  columns,
  pageSize = 10,
  pagesizeoption =[10, 25, 50, 100],
  pagination = true,
  onPageChange,
  onRowsPerPageChange = true,
  disableColumnFilter = true,
  localeText,
  showBorder = false,
  borderColor = "#D3D9E1",
  rowHighlight = false,
  rowColor = "#f5f5f5",
  rowColorEven = "#ffffff",
  rowColorOdd = "#f9f9f9",
  columnColor = false,
  columnCellColor = "#f4f4f4",
  headerColor = "F4F8FA",
  ...rest
}) => {
  const tableRef = useRef(null);
  const [stickyHeader, setStickyHeader] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize:10,
  });
  const getCellClassName = (params) => {
    if (params.field === "IconColumnName" || typeof params.value === "object") {
      return "iconCellCenter"; // Apply class to center icons
    }
    if (
      params.field === "Status" ||
      params.field === "Actions" ||
      params.value == null
    ) {
      return "";
    }
    if (typeof params.value === "number" || !isNaN(Number(params.value))) {
      return "numberCellRight"; 
    }
    return "textCellLeft"; 
  };

  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row";
  }

  useEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        const { top } = tableRef.current.getBoundingClientRect();
        setStickyHeader(top <= 70); // Trigger sticky behavior when within 60px of the top
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box ref={tableRef}>
      <DataGrid
        rows={rows}
        columns={columns.map((column) => ({
          ...column,
          flex: 1,
          editable: false,
          cellClassName: getCellClassName,
        }))}
        pagination={true}
        rowHeight={false}
        pageSizeOptions={pagesizeoption}
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => {
          setPaginationModel(newModel);
          if (onPageChange) onPageChange(newModel.page);
        }}
        autoHeight={false}
        localeText={localeText}
        checkboxSelection
        disableRowSelectionOnClick 
        getRowClassName={getRowClassName}
        sx={{
          width: "100%",
          marginBottom:"5rem",
          fontSize: "12px",
          borderRight:"none",
          border: `1px solid ${borderColor}`, 
          borderRadius: "8px",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: headerColor, 
            textAlign: "center",
            fontSize: "14px",
            position: stickyHeader ? "fixed" : "sticky",
            top: stickyHeader ? "48px" : "0",
            zIndex: stickyHeader ? 1000 : "auto",
            textTransform:"capitalize",
            fontFamily: "work sans",
          },
          "& .odd-row": {
            backgroundColor: rowColorOdd,
          },
          "& .even-row": {
            backgroundColor: rowHighlight ? rowColor : rowColorEven,
          },
          "& .MuiDataGrid-cell": {
            borderColor: showBorder ? borderColor : "",
            borderWidth: showBorder ? "1px" : "",
            borderStyle: "solid",
            backgroundColor: columnColor ? columnCellColor : "transparent",
            fontSize: "14px",
            textTransform:"capitalize",
            borderLeft: "none", 
            borderRight: "none", 
            borderBottom: showBorder ? `0.5px solid ${borderColor}` : "none",
            fontFamily: "work sans",
          },
      
         
          '& .MuiDataGrid-cell[data-field="columnName"]': {
            backgroundColor: columnColor ? "#e0f7fa" : "transparent",
          },
          '& .MuiDataGrid-cell[data-field="anotherColumn"]': {
            backgroundColor: columnColor ? "#FFEBEE" : "transparent",
          },
          "& .MuiTablePagination-actions": {
            color: "#1976d2",
            "&:hover": { color: "#000000" },
          },
          "& .MuiToolbar-root": {
            alignItems: "center" ,},
          '& .MuiTablePagination-selectLabel':{
            margin:"0",
          },
          '& .MuiTablePagination-displayedRows':{
            margin:"0",
          },
          "& .MuiToolbar-gutters": {
            padding: "0",
          },
          "& .MuiToolbar-regular": {
            height: "48px",
          },
          "& .MuiDataGrid-scrollbar--vertical":{
            overflowY:"hidden",
          },
          "& .MuiTablePagination-toolbar": {
            backgroundColor: "#f4f8fa",
          },
          "& .MuiDataGrid-virtualScroller": {
            maxHeight: "calc(100% - 16rem)",
            overflowY: "hidden",
            scrollBehavior:"smooth",
          },
          "& .numberCellRight": {
            textAlign: "right",
          },
          "& .textCellLeft": {
            textAlign: "left",
          },
          "& .iconCellCenter": {
            display: "flex",
            FormatAlignJustify:"center",
            alignItems:" center",
            textAlign: "center", 
          },
          "& .MuiDataGrid-row--borderBottom.css-yrdy0g-MuiDataGrid-columnHeaderRow":
            {
              backgroundColor: "#F4F8FA",
            },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: "#f4f8fa",
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600,
          },
          '& .MuiDataGrid-footerContainer': {
            display: rows.length >= 10 ? "block" : "none",
          },
        }}
        {...rest}
      />
    </Box>
  );
};

export default DataGridTable;