import React, { useState } from "react";

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userName = "Anonymous"; // Replace with dynamic user name if available

  const handleLogout = () => {
    // Implement logout functionality
    console.log("User logged out");
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className=" px-3 h-12 flex items-center justify-between bg-[#D0DDE4] z-10 sticky top-0">
      {/* Logo */}
      <div className=" text-lg font-bold">
        <img src="/Images/career connect logo.svg" alt="Logo"/>
      </div>

      {/* User Profile */}
      <div className="relative mr-2">
        <button
          onClick={toggleDropdown}
          className="flex items-center space-x-2 text-white"
        >
          <div className="w-8 h-8 bg-[#6491A9] text-white rounded-full flex items-center justify-center font-bold">
            {userName.charAt(0)}
          </div>
          <span className="text-[#446271]">{userName}</span>
        </button>

        {/* Dropdown */}
        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1">
            <button
              onClick={handleLogout}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
