import React, { useState } from "react";
import { CloudUpload } from "@mui/icons-material"; // Importing Material Icon
import CustomButton from "../../Button/Button";

function CreateCandidateForm({ onClose, onSubmit }) {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName:"",
    email:"",
    designation: "",
    experience: "",
  });

  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [showFileInput, setShowFileInput] = useState(true); // State to manage file input visibility

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    // Clear error message for the field if it has a value
    if (value && errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.type !== "application/pdf") {
        alert("Only PDF files are allowed.");
        return;
      }

      if (selectedFile.size > 5 * 1024 * 1024) {
        alert("File size should be less than or equal to 5 MB.");
        return;
      }

      // Clear any existing file error if a valid file is selected
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.file;
        return newErrors;
      });
      // console.log("File name",selectedFile.name)
      setFile(selectedFile);
      setShowFileInput(false); // Hide file input button after selecting a file
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      if (droppedFile.type !== "application/pdf") {
        alert("Only PDF files are allowed.");
        return;
      }

      if (droppedFile.size > 5 * 1024 * 1024) {
        alert("File size should be less than or equal to 5 MB.");
        return;
      }

      setFile(droppedFile);
      setShowFileInput(false); // Hide file input button after dropping a file
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setShowFileInput(true); // Show file input button again
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formValues.firstName) newErrors.firstName = "First name is required";
    if (!formValues.email) newErrors.email = "Email is required";
    if (!file) newErrors.file = "Resume is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append("firstName", formValues.firstName);
      formData.append("lastName", formValues.lastName);
      formData.append("email", formValues.email);
      formData.append("designation", formValues.designation);
      formData.append("experience", formValues.experience);
      if (file) {
        formData.append("file", file); // Append file blob to FormData
      }
      onSubmit(formData); // Send FormData to parent component
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content customeJdFromWidth">
        <div className="modal-header flex justify-start p-2 items-center mb-2 open-sans-600">
          <h3>Create Candidate</h3>
          {/* <button onClick={onClose} className="close-button">
            &times;
          </button> */}
        </div>
        <form onSubmit={handleSubmit} style={{ margin: "20px" }}>
          
          <div className="flex justify-start">
            <div className="form-group mr-6">
              <label>First Name<span style={{ color: "red" }}>*</span></label>
              <input
                type="text"
                name="firstName"
                value={formValues.firstName}
                onChange={handleChange}
                className="form-input"
              />
              {errors.firstName && (
                <span className="error-text">{errors.firstName}</span>
              )}
            </div>
            <div className="form-group" style={{width:"274px"}}>
              <label>Last Name</label>
              <input
                type="text"
                name="lastName"
                value={formValues.lastName}
                onChange={handleChange}
                className="form-input max-w-57 w-64"
              /><br/>
            </div>
          </div>

          <div className="form-group">
            <label>
              Email <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              className="form-input"
            />
            {errors.email && <span className="error-text">{errors.email}</span>}
          </div>
          <div className="flex justify-start">
            <div className="form-group mr-6">
              <label>Designation</label>
              <input
                type="text"
                name="designation"
                value={formValues.designation}
                onChange={handleChange}
                className="form-input"
              />
              {errors.designation && (
                <span className="error-text">{errors.designation}</span>
              )}
            </div>
            <div className="form-group">
              <label>Total Experience</label>
              <input
                type="text"
                name="experience"
                value={formValues.experience}
                onChange={handleChange}
                className="form-input max-w-52"
              />
            </div>
          </div>

          {/* File Upload Dropzone */}
          <div
            className="dropzone border border-gray-300 rounded flex items-center p-4 mt-4"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            style={{ backgroundColor: "#F9FAFB" }}
          >
            {showFileInput && (
              <>
                <div className="flex items-center">
                  <div className="icon bg-gray-200 p-2 rounded">
                    <CloudUpload className="text-gray-500" fontSize="large" />
                  </div>
                  <div className="ml-4">
                    <p className="text-sm font-semibold relative top-1 mb-2">
                      Drag and Drop resume to upload<span style={{ color: "red" }}>*</span>
                    </p>
                    <p className="text-xs text-gray-500">
                      PDF files only, not more than 5 MB
                    </p>
                  </div>
                </div>

                <input
                  type="file"
                  id="file-upload"
                  onChange={handleFileChange}
                  accept="application/pdf"
                  className="hidden"
                />
                <label
                  htmlFor="file-upload"
                  className="ml-auto text-[#6491A9] cursor-pointer border border-[#6491A9] py-1 px-4 rounded"
                >
                  Select File
                </label>
              </>
            )}
            {file && (
              <div className="flex items-center">
                <span className="text-sm font-semibold">{file.name}</span>
                <button
                  type="button"
                  onClick={handleRemoveFile}
                  className="ml-4 text-red-500"
                >
                  Remove
                </button>
              </div>
            )}
            {errors.file && <span className="error-text">{errors.file}</span>}

          </div>

          <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={onClose}
              className="submit-button text-[#6491A9] bg-white border border-[#6491A9] p-2 px-3 rounded"
            >
              Back
            </button>
            <div  className='ml-5'>
            <CustomButton label="Create Candidate" type="submit"   className='ml-5'/>
            </div>
            {/* <button
              type="submit"
              className="submit-button bg-[#6491a9] text-white py-2 px-12 ml-5 rounded"
            >
              Create Candidate
            </button> */}
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateCandidateForm;
