import React, { useState , useEffect } from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { FormControl, MenuItem, Select } from "@mui/material";
import apiService from "../../service";
import CustomButton from "../Button/Button";
function CreateJDForm({ onClose, onSubmit }) {
  // Form state
  const [formValues, setFormValues] = useState({
    title: "",
    experience: "",
    companyName: "", // This is client
    department: "",
    openings: "",
    jobDescription: "",
  });

  // Sample department data - you can replace this with data from an API or state
  const departments = [
    { id: 1, name: "Engineering" },
    { id: 2, name: "Marketing" },
    { id: 3, name: "Sales" },
    { id: 4, name: "Human Resources" },
  ];

  // Sample client data - you can replace this with data from an API or state
  const clients = [
    { id: 1, name: "Agiliztech" },
    { id: 2, name: "Microsoft" },
    { id: 3, name: "Salesforce" },
    { id: 4, name: "Human Connect" },
  ];

  const [client, setClient] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [logoUrl, setLogoUrl] = useState("");

  // Error state for form validation
  const [errors, setErrors] = useState({});

   // Fetch clients and departments from API
   useEffect(() => {
    // Replace with your API call
    const fetchClientData = async () => {
      try {
        const response = await apiService.getClientList(); // Replace with your API URL
        // console.log("API response:",response)
       // Check if response is successful and has data
       if (response && response.detail === "Successful" && Array.isArray(response.data)) {
        const clientList = response.data.map((client) => ({
          id: client._id, // Assuming each client object has an `id` field
          name: client.name, // Assuming each client object has a `name` field
          department:client.departments,
          logo:client.logo.data
        }));
        // console.log("clientList",clientList)
        setClient(clientList);
      }
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchClientData();
  }, []);
  
  // Handle client selection
  const handleClientChange = (event) => {
    const selectedClient = event.target.value; // Get the selected client object
    // console.log("selected client",selectedClient)
    setSelectedClient(selectedClient); // Set the selected client
    const getDepartments=selectedClient.department
    const departmentsList = getDepartments.map((dept) => ({
      name: dept.name,
      id: dept._id
  }));
  setDepartment(departmentsList);
  setFormValues((prevValues) => ({
    ...prevValues,
    companyName: selectedClient.name,
    // clientId: selectedClient.id,
  }));
  setLogoUrl(selectedClient.logo); // Set the logo URL from the selected client
  };
  const handleDepartmentChange =(event) =>{
    const selectedDep = event.target.value; // Get the selected client object
    // console.log("selected department",selectedDep)
    setSelectedDepartment(selectedDep); // Set the selected client
    setFormValues((prevValues) => ({
      ...prevValues,
      department: selectedDep
      // clientId: selectedClient.id,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  // Form validation function
  const validateForm = () => {
    const newErrors = {};
    if (!formValues.title) newErrors.title = "Job Title is required";
    if (!formValues.companyName) newErrors.companyName = "Client is required";
    if (!formValues.department) newErrors.department = "Department is required";
    if (!formValues.jobDescription)
      newErrors.jobDescription = "Job Description is required";
    // Add more validation as needed
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (validateForm()) {
    //   console.log("Form Data:", formValues);
    //   onSubmit(formValues); // Send valid form data to parent component
    // }
    if (validateForm()) {
      const submissionData = {
        ...formValues,
        logo: logoUrl, // Include the logo URL in the submission data
      };
      // console.log("Form Data:", submissionData);
      onSubmit(submissionData); // Send the data to parent component
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content customeJdFromWidth">
        <div className="modal-header flex justify-start p-2 items-center open-sans-600">
          <h3>Create JD</h3>
          {/* <button onClick={onClose} className="close-button">
            &times;
          </button> */}
        </div>
        <form onSubmit={handleSubmit} style={{ margin: "20px" }}>
          <div className="flex justify-start">
            <div className="form-group mr-6">
              <label>
                Job Title <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="title"
                value={formValues.title}
                onChange={handleChange}
                className="form-input"
              />
              {errors.title && (
                <span className="error-text">{errors.title}</span>
              )}
            </div>
            <div className="form-group">
              <label>Total Work Experience</label>
              <input
                type="text"
                name="experience"
                value={formValues.experience}
                onChange={handleChange}
                className="form-input"
                style={{
                  width:"60%",
                }}
              />
            </div>
          </div>
          <div className="flex justify-around">
            <div className="form-group" style={{ width: "33%" }}>
              <label className="mb-1">Client</label>
              <FormControl fullWidth margin="none" size="small">
                <Select
                  id="companyName"
                  name="companyName"
                  // value={formValues.client}
                  value={selectedClient}
                  onChange={handleClientChange}
                >
                  {client.map((cli) => (
                    <MenuItem key={cli._id} value={cli}>
                      {cli.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="form-group" style={{ width: "33%" }}>
              <label className="mb-1">
                Department <span style={{ color: "red" }}>*</span>
              </label>
              <FormControl fullWidth margin="none" size="small">
                <Select
                  id="department"
                  name="department"
                  // value={formValues.department}
                  value={selectedDepartment}
                  onChange={handleDepartmentChange}
                  error={!!errors.department}
                >
                  {department.map((dept) => (
                    <MenuItem key={dept._id} value={dept.name}>
                      {dept.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.department && (
                <span className="error-text">{errors.department}</span>
              )}
            </div>

            <div className="form-group" style={{ width: "33%" }}>
              <label>Openings</label>
              <input
                type="text"
                name="openings"
                value={formValues.openings}
                onChange={handleChange}
                className="form-input"
              />
            </div>
          </div>

          <div className="form-group">
            <label className="mb-1">
              Paste your Job Description Here{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <TextareaAutosize
              minRows={3}
              placeholder="Minimum 3 rows"
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
              name="jobDescription"
              value={formValues.jobDescription}
              onChange={handleChange}
            />
            {errors.jobDescription && (
              <span className="error-text">{errors.jobDescription}</span>
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="submit-button text-[#6491A9] bg-white border border-[#6491A9] p-2 px-3 rounded"
            >
              Back
            </button><div className=" ml-4">
            <CustomButton label="Create" type="submit" />
            </div>
{/* 
            <button
              type="submit"
              className="submit-button bg-[#6491A9] text-white py-2 px-12 ml-10 rounded"
            >
              Create
            </button> */}
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateJDForm;
