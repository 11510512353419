import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  gridClasses,
} from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
// import BorderColorIcon from "@mui/icons-material/BorderColor";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { red } from "@mui/material/colors";

import apiService from "../../service";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/SideBar";
import Footer from "../Common/footer";
import { Link } from "react-router-dom";
import CreateJobForm from "./CreateJDForm";
import stringToColor from "../../services/stringToColor";
import ClipLoader from "react-spinners/ClipLoader"; // Import ClipLoader
import ReactPaginate from "react-paginate"; // Import react-paginate
import "../JobOpenings/JobOpenings.css"; // Create this CSS file for custom styles
import DataGridTable from "../Datagrid/dataGridTable";
import CustomButton from "../Button/Button";
const columns: GridColDef[] = [
  {
    field: "jobTitle",
    headerName: "Job Title",
    headerClassName: "headerBackground",
    headerAlign: "left",
    sortable: true,
    renderCell: (params) => (
      <Link
        to="/jobDetails"
        className="flex items-left p- w-full text-[#6491A9] font-[600] transition-colors no-underline"
      >
        <span>{params.value}</span>
      </Link>
    ),
  },
  {
    field: "totalExperience",
    headerName: "Total experience",
    headerClassName: "headerBackground",
    headerAlign: "left",
    align: "left",
    justifyContent: "left",
    sortable: true,
  },
  {
    field: "Department",
    headerName: "Department",
    headerClassName: "headerBackground",
    headerAlign: "left",
    sortable: true,
  },
  {
    field: "Client",
    headerName: "Client",
    headerClassName: "headerBackground",
    headerAlign: "left",
    sortable: true,
    renderCell: (params) => (
      <div className="flex flex-nowrap justify-left align-middle w-full">
        <div className="mr-1">
          <img src={params.value.logo} alt="logo" />
          {/* <img src="/Images/AgilizTechLogo.png" alt="logo" />  */}
        </div>
        <div> {params.value.name}</div>
        {/* <div> dummy name</div> */}
      </div>
    ),
  },
  {
    field: "Openings",
    headerName: "Openings",
    headerClassName: "headerBackground",
    headerAlign: "left",
    // align: "right",
    sortable: true,
  },
  {
    field: "Candidates",
    headerName: "Candidates",
    headerClassName: "headerBackground",
    headerAlign: "left",
    sortable: true,
    },
  {
    field: "Status",
    headerName: "Status",
    headerClassName: "headerBackground",
    headerAlign: "center",
    sortable: true,

    renderCell: (params) => {
      let backgroundColor;
      let textColor;
      switch (params.value) {
        case "In-Review":
          backgroundColor = "#E5EFF4";
          textColor = "#6491A9";
          break;

        case "Withdrawn":
          backgroundColor = "#F6F6F6";
          textColor = "#9B9B9B";
          break;

        default:
          backgroundColor = "transparent";
          textColor = "#5F92DD";
      }
      return (
        <div className="text-center">
          <span
            style={{
              backgroundColor,
              color: textColor,
              padding: "5px 20px",
              borderRadius: "15px",
              textAlign: "center",
              textWrap: "nowrap",
              width: "100%",
            }}
          >
            {params.value}
          </span>
        </div>
      );
    },
  },
  {
    field: "createdBy",
    headerName: "Created By",
    headerClassName: "headerBackground",
    headerAlign: "left",
    sortable: true,
    renderCell: (params) => {
      // Generate a consistent background color for the initial based on the name
      const backgroundColor = stringToColor(params.value);
      return (
        <div className="flex items-center space-x-2 justify-center items-center h-full">
          <div
            className="w-7 h-7 text-white rounded-full flex font-bold"
            style={{ backgroundColor }}
          >
            {params.value.charAt(0)}
          </div>
          <span className="text-[#446271] font-normal">{params.value}</span>
        </div>
      );
    },
  },
  {
    field: "createdOn",
    headerName: "Created On",
    headerClassName: "headerBackground",
    headerAlign: "left",
    sortable: true,
  },
  {
    field: "Actions",
    headerName: "Actions",
    headerClassName: "headerBackground",
    headerAlign: "center",
    align: "center",
    sortable: false,
    renderCell: (params) => (
      <div>
        <IconButton aria-label="view">
          {/* <BorderColorIcon fontSize="small" /> */}
          <BorderColorOutlinedIcon fontSize="small" />
        </IconButton>

        <IconButton aria-label="delete">
          <DeleteOutlineIcon fontSize="small" sx={{ color: [red[400]] }} />
        </IconButton>
      </div>
    ),
  },
];

const rows = [
  {
    id: 1,
    jobTitle: "Lead Developer",
    totalExperience: "6+ years",
    Department: "Engineering",
    Client: { name: "AgilizTech", logo: "/Images/AgilizTechLogo.png" },
    Openings: 2, 
    Candidates: 2,
    Status: "In-Review",
    createdBy: "Moonmoon Roy",
    createdOn: "26 Aug 2024",
  },
  {
    id: 2,
    jobTitle: "Lead Developer",
    totalExperience: "6+ years",
    Department: "Engineering",
    Client: { name: "AgilizTech", logo: "/Images/AgilizTechLogo.png" },
    Openings: 12,
    Candidates: 100,
    Status: "Withdrawn",
    createdBy: "Neeraja",
    createdOn: "26 Aug 2024",
    
  },
  {
    id: 3,
    jobTitle: "Lead Developer",
    totalExperience: "6+ years",
    Department: "Engineering",
    Client: { name: "AgilizTech", logo: "/Images/AgilizTechLogo.png" },
    Openings: 2,
    Candidates: 2,
    Status: "In-Review",
    createdBy: "Pooja",
    createdOn: "26 Aug 2024",
  },
  {
    id: 4,
    jobTitle: "Lead Developer",
    totalExperience: "6+ years",
    Department: "Engineering",
    Client: { name: "AgilizTech", logo: "/Images/AgilizTechLogo.png" },
    Openings: 2,
    Candidates: 2,
    Status: "In-Review",
    createdBy: "Neeraja",
    createdOn: "26 Aug 2024",
  },
  {
    id: 5,
    jobTitle: "Lead Developer",
    totalExperience: "6+ years",
    Department: "Engineering",
    Client: { name: "AgilizTech", logo: "/Images/AgilizTechLogo.png" },
    Openings: 2,
    Candidates: 2,
    Status: "In-Review",
    createdBy: "Moonmoon Roy",
    createdOn: "26 Aug 2024",
  },
  {
    id: 6,
    jobTitle: "Lead Developer",
    totalExperience: "6+ years",
    Department: "Engineering",
    Client: { name: "AgilizTech", logo: "/Images/AgilizTechLogo.png" },
    Openings: 2,
    Candidates: 2,
    Status: "In-Review",
    createdBy: "Moonmoon Roy",
    createdOn: "26 Aug 2024",
  },
];

function JobOpenings() {
  const [jobList, setJobList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading
  const [currentPage, setCurrentPage] = useState(0);
  const [shouldFetchData, setShouldFetchData] = useState(true);
  // Utility function to format candidate data
  const formatJDdata = (jd_data, id) => {
    const formatDate = (dateString) => {
      const defaultDate = new Date("2024-08-24T00:00:00Z"); // Default date: 24 Aug 2024
      const date = dateString ? new Date(dateString) : defaultDate;
      return new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }).format(date);
    };
    return {
      id: jd_data._id || id, // Use provided id or fallback to candidate.id
      jobTitle: jd_data.title,
      totalExperience: jd_data.experience || "N/A",
      Department: jd_data.department || "N/A",
      Client: {
        name: jd_data.companyName || "N/A",
        logo: jd_data.logo || "N/A", //"/Images/AgilizTechLogo.png" ||
      },
      Openings: jd_data.openings || 0,
      Candidates: jd_data.noOfCandidates || 0,
      Status: "In-Review",
      createdBy: "",
      createdOn: formatDate(jd_data.createdAt),
    };
  };

  useEffect(() => {
    const jobopenings = async () => {
      try {
        setLoading(true);
        const JDresponse = await apiService.getJobDetailsList();
        // console.log("Job details",JDresponse.data)
        if (
          JDresponse &&
          JDresponse.detail === "Successful" &&
          Array.isArray(JDresponse.data)
        ) {
          const clientResponse = await apiService.getClientList();
          // console.log("Client details", clientResponse.data);
          if (
            clientResponse &&
            clientResponse.detail === "Successful" &&
            Array.isArray(clientResponse.data)
          ) {
            const clientDetails = clientResponse.data;
            const formattedJD = JDresponse.data.map((jd, index) => {
              // Merge job details with corresponding client logos
              const client = clientDetails.find(
                (client) => client.name === jd.companyName
              );
              const updatedJD = { ...jd, logo: client?.logo.data }; // Add logo directly to jd
              return {
                ...formatJDdata(updatedJD, index + 1),
                // logo: client?.logo.data || null, // Attach logo if available
              };
            });
            setJobList(formattedJD);
          }
        } else {
          setJobList(rows);
        }
      } catch (error) {
        console.log(error);
        setJobList(rows);
      } finally {
        setLoading(false);
      }
    };
    if (shouldFetchData) {
      jobopenings();
      setShouldFetchData(false); // Reset state to prevent infinite loop
    }
  }, [shouldFetchData]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFormSubmit = async (JDdata) => {
    setLoading(true);
    setFormData(JDdata);
    setIsModalOpen(false);
    // payload without logo for inserting jds
    const jobData = { ...JDdata };
    delete jobData.logo;
    // console.log("Logo:", logo);
    // console.log("Job Data:", jobData);
    try {
      const response = await apiService.addJobDetails(jobData); //API call for insertion of candidate data
      console.log("Job details created successfully:", response);
      //   // Prepare the formatted candidate object using the utility function
      // const newJD = formatJDdata(
      //   {
      //     ...JDdata,
      //     _id: response.data["_id"],
      //   },
      //   jobList.length + 1
      // );
      // Update the job list after successful submission
      // setJobList((prevList) => [...prevList, newJD]);
      setShouldFetchData(true);
    } catch (error) {
      console.error("Error adding candidate:", error);
    } finally {
      // setLoading(false); // Stop loading after API call is done
    }
    // Add further processing of form data here
  };
  // Pagination settings
  const itemsPerPage = jobList.length ? jobList.length : 10
  // const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(jobList.length / itemsPerPage);

  // // Paginated data
  // const currentItems = candidateList.slice(offset, offset + itemsPerPage);
  const currentItems = jobList.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );
  // Handle page change
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const updatedColumns = columns.map((column) => {
    if (column.field === "createdBy") {
      return {
        ...column,
        minWidth: 150, // Min width
      };
    }
    return {
      ...column,
      width: 150, // Use explicit width for other columns as well
      minWidth: column.field === "Openings" || column.field === "Candidates" ? 100 : 200,
    };
  });
  

  return (
    <div className="flex-grow">
      <Navbar />
      <div className="flex w-full justify-evenly">
      <div className="bg-[#7A8286] w-[12%] sticky top-[48px] )]"
        style={{height:"95vh"}}
        >
          <Sidebar />
        </div>
        <div className="w-[88%]">
          <div className="flex-grow  p-6"

          >
            {/* <div className="flex justify-between items-center align-middle mb-4"> */}
            <div className="flex justify-between items-center align-middle mb-4 sticky top-[45px] ">
          
            <div className=" w-1/3 items-center">
                <div className="text-lg font-semibold text-[#6491A9] text-left open-sans-600">
                  Job Openings
                </div>
                {/* <p className="text-gray-500">Listing of website to publish</p> */}
              </div>
              <div className=" w-2/3">
                <div className="flex justify-end items-center">
                  <div>
                    {/* <button
                      onClick={handleOpenModal}
                      className="bg-[#6491A9] rounded-lg text-[13px] font-normal text-white p-2 px-4 open-sans-400"
                    >
                      Create JD
                    </button> */}
                      <CustomButton label="Create JD" onClick={handleOpenModal} />
                  </div>
                </div>
              </div>
            </div>
            <div>
              {loading ? (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                  <ClipLoader color="#6491A9" loading={loading} size={50} />
                </div>
              ) : (
                <Box sx={{ width: "100%", typography: "body" }}>
                  {jobList && jobList.length > 0 && (
                    <DataGridTable
                      columns={updatedColumns}
                      rows={currentItems}
                      //  rows={jobList}
                      // rowsPerPageOptions={rows.length}
                      pageSize={25}
                      showBorder={true}
                      rowHighlight={true}
                      
                    />
                    // <DataGrid
                    //   rows={currentItems}
                    //   // rows={jobList}
                    //   columns={updatedColumns}
                    //   getCellClassName={(
                    //     params: GridCellParams<any, any, number>
                    //   ) => {
                    //     if (
                    //       params.field === "Status" ||
                    //       params.field === "Actions" ||
                    //       params.value == null ||
                    //       params.field === "__check__"
                    //     ) {
                    //       return "";
                    //     }
                    //     // Check if params.value is a number (integer or decimal)
                    //     else if (
                    //       typeof params.value === "number" &&
                    //       !isNaN(params.value)
                    //     ) {
                    //       return "numberCellRight";
                    //     }

                    //     // Set className for other types (like text)
                    //     return "textCellleft";
                    //   }}
                    //   autoHeight
                    //   getRowHeight={() => "auto"} // Set row height to auto
                    //   // initialState={{
                    //   //   pagination: {
                    //   //     paginationModel: {
                    //   //       pageSize: 8,
                    //   //     },
                    //   //   },
                    //   // }}
                    //   // pageSizeOptions={[8]}
                    //   disablePagination // Disable default pagination
                    //   hideFooterPagination={true} // Hide pagination footer
                    //   // pagination={false}
                    //   pageSize={itemsPerPage}
                    //   checkboxSelection
                    //   disableRowSelectionOnClick
                    //   sx={{
                    //     width: "100%",
                    //     fontSize: "12px",
                    //     "& .MuiDataGrid-columnHeaders": {
                    //       backgroundColor: "#F4F8FA !important",
                    //       color: "#42526D",
                    //       // textAlign: "center",
                    //       fontSize: "14px",
                    //     },
                    //     "& .MuiDataGrid-columnHeader": {
                    //       backgroundColor: "#F4F8FA !important",
                    //       // textAlign: "center",
                    //     },
                    //     "& .MuiDataGrid-columnHeaderTitle": {
                    //       color: "#42526D",
                    //       fontWeight: "500",
                    //       // textAlign: "center",
                    //       fontFamily: "work sans",
                    //     },
                    //     "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                    //       {
                    //         backgroundColor: "#F4F8FA !important",
                    //         color: "#42526D !important",
                    //         borderColor: "#D3D9E1 !important", //light grey
                    //         // textAlign: "center",
                    //       },
                    //     "& .MuiDataGrid-cell": {
                    //       display: "flex",
                    //       justifyContent: "center",
                    //       alignItems: "center",
                    //       // textAlign: "center",
                    //       fontFamily: "work sans",
                    //     },
                    //     "& .MuiTablePagination-root p": {
                    //       marginBottom: 0,
                    //     },
                    //   }}
                    // />
                  )}
                  {(!jobList || jobList.length === 0) && (
                    <div>No Data Found</div>
                  )}
                  {/* React Paginate Component
                <div className="pagination-container">
          <ReactPaginate
            previousLabel={"←"}
            nextLabel={"→"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageLinkClassName={"page-link"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"page-link"}
          // /> */}
                  {/* // </div> */}
                </Box>
              )}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <CreateJobForm onClose={handleCloseModal} onSubmit={handleFormSubmit} />
      )}
      {/* <Footer /> */}
    </div>
  );
}

export default JobOpenings;
