import axios from "axios";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL;
let uniqueId = Cookies.get("uniqueId");

const HttpClient = axios.create();

HttpClient.interceptors.request.use(
  (config) => {
    if (uniqueId) {
      config.headers.Cookies = `${uniqueId}`;
    }
    console.log("Axios Request Config:", config);
    return config;
  },
  (error) => Promise.reject(error)
);

const getCandidateInfo = async (token) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  console.log("Axios Request Config:", config); // Log the request config

  try {
    const response = await HttpClient.get(
      `${API_URL}/candidate-info/${token}`,
      config
    );
    console.log(response);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const chatPdf = async (token, text) => {
  try {
    const response = await HttpClient.post(
      `${API_URL}/chat/${token}`,
      {
        question: text,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const getJdInfo = async (job_id) => {
  try {
    if (job_id){
    const response = await HttpClient.get(`${API_URL}/jd-info/${job_id}`, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(response);
    return response.data;
  }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const getChatHistory = async (id) => {
  try {
    const response = await HttpClient.get(`${API_URL}/chat-history/${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching Chat History:", error);
    throw error;
  }
};

const getAllJobOpenings = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/job-openings/`, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching Job Openings:", error);
    throw error;
  }
};

const getCandidateList = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/get-all-candidates/`, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching Candidates List:", error);
    throw error;
  }
};

//not used
const getJobOpeninigs = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/job-openings-list/`, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching Candidates List:", error);
    throw error;
  }
};

const getJobDetailsList = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/job-details-list/`, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching Candidates List:", error);
    throw error;
  }
};

const addCandidate = async (candidateData) => {
  try {
    const response = await HttpClient.post(`${API_URL}/add-candidate/`,candidateData, {
      headers: {"Content-Type": "application/json"},
      });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error adding candidate:", error);
    throw error;
  }
};

const uploadPdf = async (data) => {
  try {
    const response = await HttpClient.post(`${API_URL}/upload/`,data, {
      headers: {'Content-Type': 'multipart/form-data'},
      });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error uploading pdf:", error);
    throw error;
  }
};

const getLinkWithoutJd = async (candidate_id) => {
  try {
    const response = await HttpClient.post(`${API_URL}/create-link-without-jd/${candidate_id}`, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching Chat History:", error);
    throw error;
  }
};

const addJobDetails = async (JobDetailsData) => {
  try {
    const response = await HttpClient.post(`${API_URL}/add-jds/`,JobDetailsData, {
      headers: {"Content-Type": "application/json"},
      });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error adding job details:", error);
    throw error;
  }
};


const getClientList = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/get-all-clients/`, {
      headers: { "Content-Type": "application/json" },
    });
    // console.log(response);
    // const data = await response.json();
    return response.data;
  } catch (error) {
    console.error("Error fetching Candidates List:", error);
    throw error;
  }
};

const apiService = {
  getCandidateInfo,
  chatPdf,
  getJdInfo,
  getChatHistory,
  getAllJobOpenings,
  getCandidateList,
  getJobDetailsList,
  getJobOpeninigs,
  addCandidate,
  uploadPdf,
  getLinkWithoutJd,
  addJobDetails,
  getClientList
};

export default apiService;
