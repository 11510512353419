import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  // Get the current location object to determine the active route
  const location = useLocation();

  // Function to determine if a link is active
  const isActive = (paths) => paths.includes(location.pathname);

  return (
    <div
      className={`fixed lg:static  w-full flex flex-col transition-transform h-[93vh] relative${
        isOpen
          ? "transform translate-x-0"
          : "transform -translate-x-full lg:translate-x-0"
      }`}
    >
      {/* Toggle Button for Mobile */}
      <button className="lg:hidden p-4" onClick={() => setIsOpen(!isOpen)}>
        <FaBars className="text-white" />
      </button>

      {/* Logo */}
      {/* <div className="flex items-center justify-center h-20">
        <img src="./Images/login_logo.png" alt="Logo" className="h-16" />
      </div> */}

      {/* Menu */}
      <div className="flex-grow flex flex-col justify-between">
        <nav className="open-sans-600" style={{fontSize:"12px"}}>
          <Link
            to="/jobopenings"
            className={`flex justify-start items-center px-2 py-4 transition-colors no-underline ${
              isActive(["/jobopenings", "/jobDetails"])
                ? "text-[#FAFDFE] font-semibold" // Apply active styles if the path matches
                : "text-[#ffffff98] hover:font-semibold hover:text-[#FAFDFE]"
            }`}
          >
            <span><DescriptionOutlinedIcon/> </span> <span style={{
              position:"relative",
              top:"0px",
              paddingLeft:"8px",
            }}>Job Openings</span>
          </Link>
          <Link
            to="/Candidates"
            className={`flex justify-start text-center items-center px-2 pb-4 transition-colors no-underline ${
              isActive(["/Candidates", "/Candidates/profile"])
                ? "text-[#FAFDFE] font-semibold" // Apply active styles if the path matches
                : "text-[#ffffff98] hover:font-semibold hover:text-[#FAFDFE]"
            }`}
          >
            <span><PersonOutlineOutlinedIcon />  </span>
            <span style={{
              position:"relative",
              top:"1px",
              paddingLeft:"8px",
            }}>Candidates</span>
          </Link>
        </nav>
        <div className="text-[#ffffff98] whitespace-break-spaces text-[12px]  text-left" >
          <p className="flex items-start gap-1 flex-col m-0 pt-3 pr-3 pl-3">
            <span>©2024 CareerConnector Powered by AgilizTech</span>
          </p>
        </div>

      </div>
    </div>
  );
}

export default Sidebar;
