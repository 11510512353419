import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IconButton, Tooltip } from "@mui/material";
// import BorderColorIcon from "@mui/icons-material/BorderColor";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { grey, red } from "@mui/material/colors";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ShareIcon from "@mui/icons-material/Share";
import CreateCandidateForm from "../JobOpenings/JobDetails/CreateCandidateForm";
import GenerateLinkModal from "../JobOpenings/JobDetails/GenerateLinkModal";
import apiService from "../../service";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/SideBar";
import Footer from "../Common/footer";
import ClipLoader from "react-spinners/ClipLoader"; // Import ClipLoader
import ReactPaginate from "react-paginate"; // Import react-paginate
import "./Candidates.css"; // Create this CSS file for custom styles
import { isOverflown } from "@mui/x-data-grid/utils/domUtils";
import DataGridTable from "../Datagrid/dataGridTable";
import CustomButton from "../Button/Button";
const rows = [
  {
    id: 1,
    candidateName: "Gaurav Singh",
    designation: "Sr. Developer",
    contact: {
      phone: 9876543212,
      email: "test@email.com",
    },
    appliedJobs: {
      job: "Lead Developer",
      city: "Bangalore, KA",
    },
    Status: "New",
    source: "Linkedin",
    appliedOn: "26 Aug 2024",
  },
  {
    id: 2,
    candidateName: "Balveer Singh",
    designation: "UX Desiginer",
    contact: {
      phone: 9876543212,
      email: "test@email.com",
    },
    appliedJobs: {
      job: "Lead Developer",
      city: "Bangalore, KA",
    },
    Status: "Screening",
    source: "Career Page",
    appliedOn: "26 Aug 2024",
  },
  {
    id: 3,
    candidateName: "Gaurav Singh",
    designation: "Sr. Developer",
    contact: {
      phone: 9876543212,
      email: "test@email.com",
    },
    appliedJobs: {
      job: "Lead Developer",
      city: "Bangalore, KA",
    },
    Status: "New",
    source: "Linkedin",
    appliedOn: "26 Aug 2024",
  },
  {
    id: 4,
    candidateName: "Gaurav Singh",
    designation: "Sr. Developer",
    contact: {
      phone: 9876543212,
      email: "test@email.com",
    },
    appliedJobs: {
      job: "Lead Developer",
      city: "Bangalore, KA",
    },
    Status: "New",
    source: "Linkedin",
    appliedOn: "26 Aug 2024",
  },
];

function Candidates() {
  const [candidateList, setCandidateList] = useState([]);
  const [candidateformData, setCandidateFormData] = useState(null);
  const [isCandidateModalOpen, setIsCandidateModalOpen] = useState(false);
  const [isGenerateLinkModalOpen, setIsGenerateLinkModalOpen] = useState(false);
  const [generatedLink, setGeneratedLink] = useState(null);
  const [candidateName, setCandidateName] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading
  const [currentPage, setCurrentPage] = useState(0);
  const [shouldFetchData, setShouldFetchData] = useState(true);

  // Utility function to format candidate data
  const formatCandidateData = (candidate, id) => {
    const formatDate = (dateString) => {
      const defaultDate = new Date("2024-08-24T00:00:00Z"); // Default date: 24 Aug 2024
      const date = dateString ? new Date(dateString) : defaultDate;
      return new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }).format(date);
    };
    return {
      id: candidate._id || id, // Use provided id or fallback to candidate.id
      candidateName: `${candidate.firstName} ${candidate.lastName}`,
      designation: candidate.designation || "N/A",
      contact: {
        // phone: candidate.phone || "N/A",
        email: candidate.email || "N/A",
      },
      appliedJobs: {
        job: "",
        city: "",
      },
      Status: candidate.status || "New",
      source: "",
      appliedOn: formatDate(candidate.createdAt),
    };
  };

  useEffect(() => {
    const candidateDetails = async () => {
      try {
        setLoading(true); // Start loading
        // const response = await apiService.getJobOpeninigs();
        const response = await apiService.getCandidateList();

        if (
          response &&
          response.detail === "Successful" &&
          Array.isArray(response.data)
        ) {
          const formattedCandidates = response.data.map((candidate, index) =>
            formatCandidateData(candidate, index + 1)
          );
          // console.log("Whole response:",formattedCandidates);
          // setCandidateList(response.data);
          setCandidateList(formattedCandidates);
        } else {
          setCandidateList(rows);
        }
      } catch (error) {
        console.log(error);
        setCandidateList(rows);
      } finally {
        setLoading(false);
      }
    };
    if (shouldFetchData) {
      candidateDetails();
      setShouldFetchData(false); // Reset state to prevent infinite loop
    }
  }, [shouldFetchData]);

  const handleOpenGenerateLinkModal = async (candidate) => {
    const candidateId = candidate.id;
    const candidateName = candidate.candidateName;
    console.log("name:", candidateName);
    try {
      // Send FormData to the backend via API
      const response = await apiService.getLinkWithoutJd(candidateId); //API call for insertion of candidate data
      const newToken = response.data.token;
      console.log("Genretaed token:", newToken);

      const API_URL = process.env.REACT_APP_API_URL;
      let frontendURL = API_URL;
      if (
        API_URL === "http://127.0.0.1:8000" ||
        API_URL === "http://localhost:8000"
      ) {
        frontendURL = "http://localhost:3000";
      }
      if (API_URL === "https://dev-careerconnector-api.agiliztech.ai") {
        frontendURL = "https://dev-careerconnector-ui.agiliztech.ai";
      }
      const newLink = `${frontendURL}/cvview/${newToken}`;
      console.log("Generated link:", newLink);
      setCandidateName(candidateName);
      setGeneratedLink(newLink);
      setIsGenerateLinkModalOpen(true);
    } catch (error) {
      console.error("Error in generating token:", error);
      // setGeneratedLink("");
      setIsGenerateLinkModalOpen(true);
    }
  };
  // Pagination settings
  const itemsPerPage = candidateList.length ? candidateList.length : 10
  // const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(candidateList.length / itemsPerPage);

  // // Paginated data
  // const currentItems = candidateList.slice(offset, offset + itemsPerPage);
  const currentItems = candidateList.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );
  // Handle page change
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleCloseModal = () => {
    setIsCandidateModalOpen(false);
    setIsGenerateLinkModalOpen(false);
  };

  const handleOpenCandidateModal = () => {
    setIsCandidateModalOpen(true);
  };

  const handleFormSubmit = async (data) => {
    setLoading(true); // Start loading
    setCandidateFormData(data);
    setIsCandidateModalOpen(false);
    const CandidateData = {};
    let cvLocation = null; //initially empty
    const formData = new FormData();
    // Convert FormData to JSON
    data.forEach((value, key) => {
      if (key !== "file") {
        CandidateData[key] = value;
      } //Preparing candidate data
      if (key === "file") {
        formData.append("pdf_file", value);
      } // Append the selected file to FormData
    });
    CandidateData["cvLocation"] = cvLocation; //Will update this cv location after storing cv embeddings
    console.log("Candidate Data:", CandidateData);
    try {
      // Send FormData to the backend via API
      const response = await apiService.addCandidate(CandidateData); //API call for insertion of candidate data
      console.log("Candidate created successfully:", response);
      //CV uploading process
      formData.append("candidateId", response.data["_id"]); // Pass the candidate ID from the response
      formData.append("first_name", CandidateData.firstName); // Pass the first name from CandidateData
      console.log(
        "Successfully prepared Formdata for uploading pdf:",
        formData
      );

      const cv_response = await apiService.uploadPdf(formData); // API call for uploading the Cv amd storing embeddingds
      console.log("Pdf uploaded successfully:", cv_response);

      //   // Prepare the formatted candidate object using the utility function
      // const newCandidate = formatCandidateData(
      //   {
      //     ...CandidateData,
      //     _id: response.data["_id"],
      //   },
      //   candidateList.length + 1
      // );
      //   // Update the candidate list after successful submission
      //   setCandidateList((prevList) => [...prevList, newCandidate]);
      // setCandidateList((prevList) => [...prevList, response.candidate]);
      setShouldFetchData(true);
    } catch (error) {
      console.error("Error adding candidate:", error);
    } finally {
      // setLoading(false); // Stop loading after API call is done
    }
  };

  const columns: GridColDef[] = [
    {
      field: "candidateName",
      headerName: "Candidate Name",
      headerClassName: "headerBackground",
      // headerAlign: "center",
      sortable: true,
    },
    {
      field: "designation",
      headerName: "Designation",
      headerClassName: "headerBackground",
      // headerAlign: "center",
      sortable: true,
    },
    {
      field: "contact",
      headerName: "Contact",
      headerClassName: "headerBackground",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const email = params.row.contact?.email || "N/A";
        return (
          <div>
            {/* <IconButton aria-label="view">
          <PhoneIcon fontSize="small" />
        </IconButton> */}
            <Tooltip title={`${email}`} arrow>
              <IconButton aria-label="delete">
                <EmailOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "appliedJobs",
      headerName: "Applied For Job",
      headerClassName: "headerBackground",
      // headerAlign: "center",
      sortable: true,
      renderCell: (params) => (
        <div>
          <div>{params.value.job}</div>
          <div className="text-[10px]">{params.value.city}</div>
        </div>
      ),
    },
    {
      field: "Status",
      headerName: "Status",
      headerClassName: "headerBackground",
      headerAlign: "center",
      sortable: true,

      renderCell: (params) => {
        let backgroundColor;
        let textColor;
        switch (params.value) {
          case "New":
            backgroundColor = "#E5EFF4";
            textColor = "#6491A9";
            break;

          case "Screening":
            backgroundColor = "#EEE2FF";
            textColor = "#794ABD";
            break;

          default:
            backgroundColor = "transparent";
            textColor = "#5F92DD";
        }
        return (
          <div className="text-center">
            <span
              style={{
                backgroundColor,
                color: textColor,
                padding: "5px 20px",
                borderRadius: "15px",
                textAlign: "center",
                textWrap: "nowrap",
                width: "100%",
              }}
            >
              {params.value}
            </span>
          </div>
        );
      },
    },
    {
      field: "source",
      headerName: "Source",
      headerClassName: "headerBackground",
      // headerAlign: "center",
      sortable: true,
    },
    {
      field: "appliedOn",
      headerName: "Applied On",
      headerClassName: "headerBackground",
      // headerAlign: "center",
      sortable: true,
    },
    {
      field: "Actions",
      headerName: "Actions",
      headerClassName: "headerBackground",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <div>
          <IconButton
            aria-label="share"
            onClick={() => handleOpenGenerateLinkModal(params.row)}
          >
            <ShareIcon fontSize="small" />
          </IconButton>

          <IconButton aria-label="view">
            {/* <BorderColorIcon fontSize="small" /> */}
            <BorderColorOutlinedIcon fontSize="small" />
          </IconButton>

          <IconButton aria-label="delete">
            <DeleteOutlineIcon fontSize="small" sx={{ color: [red[400]] }} />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    //   <div className="flex-grow"
    //  >
    //     <Navbar />
    //     <div className="flex w-full justify-evenly"
    //     >
    //       <div
    //         className="bg-[#7A8286] w-[12%]"
    //         style={{ height: "calc(100vh - 6rem)",
    //                  position:"sticky",
    //                  top:"48px",
    //         }
    //       }
    //       >
    //         <Sidebar />
    //       </div>
    //       <div className="w-[88%]">
    //         <div className="flex-grow  p-6">
    //           <div className="flex justify-between items-center align-middle mb-4 sticky top-[77px]" >
    //             <div className=" w-2/5 items-center flex flex-nowrap">
    //               <div className="text-lg font-semibold text-[#6491A9] text-left open-sans-600 mr-6 w-1/5">
    //                 Candidates
    //               </div>
    //               <div className="w-3/5">
    //                 <div className="flex items-center border border-gray-300 rounded-xl px-2 py-1 shadow-sm bg-[#EBF2F6] ">
    //                   <SearchIcon className="text-gray-500 mr-2" />
    //                   <InputBase
    //                     placeholder="Search a candidate by name, email and phone number"
    //                     className="flex-1 text-sm focus:outline-none "
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //             <div className=" w-2/5 items-center">
    //               {/* <div className="flex justify-end items-center"> */}
    //               <div className="flex justify-between items-center align-middle mb-4 sticky top-77">
    //                 <div>
    //                   <button
    //                     onClick={handleOpenCandidateModal}
    //                     className="bg-[#6491A9] rounded-lg text-[13px] font-normal text-white p-2 px-4 open-sans-400"
    //                   >
    //                     Add Candidate
    //                   </button>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           <div>
    //             {loading ? ( // Show loader while loading is true
    //               <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    //                 <ClipLoader color="#6491A9" loading={loading} size={50} />
    //               </div>
    //             ) : (
    //               <Box sx={{ width: "100%", typography: "body" }}>
    //                 {candidateList && candidateList.length > 0 ? (
    //                   // <DataGrid
    //                   //   rows={currentItems}
    //                   //   columns={columns.map((column) => ({
    //                   //     ...column,
    //                   //     flex: 1, // Makes columns flexible to occupy available space
    //                   //     editable: false, // Disable editing for each column
    //                   //   }))}

    //                   //   getCellClassName={(
    //                   //     params: GridCellParams<any, any, number>
    //                   //   ) => {
    //                   //     if (
    //                   //       params.field === "contact" ||
    //                   //       params.field === "Status" ||
    //                   //       params.field === "Actions" ||
    //                   //       params.value == null ||
    //                   //       params.field === "__check__"
    //                   //     ) {
    //                   //       return "";
    //                   //     }
    //                   //     // Check if params.value is a number (integer or decimal)
    //                   //     else if (
    //                   //       typeof params.value === "number" &&
    //                   //       !isNaN(params.value)
    //                   //     ) {
    //                   //       return "numberCellRight";
    //                   //     }

    //                   //     // Set className for other types (like text)
    //                   //     return "textCellleft";
    //                   //   }}
    //                   //   autoHeight
    //                   //   getRowHeight={() => "auto"} // Set row height to auto
    //                   //   // initialState={{
    //                   //   //   pagination: {
    //                   //   //     paginationModel: {
    //                   //   //       pageSize: 8,
    //                   //   //     },
    //                   //   //   },
    //                   //   // }}
    //                   //   disablePagination // Disable default pagination
    //                   //   hideFooterPagination={false} // Hide pagination footer
    //                   //   pagination={false}
    //                   //   pageSize={itemsPerPage}
    //                   //   checkboxSelection
    //                   //   disableRowSelectionOnClick
    //                   //   sx={{
    //                   //     width: "100%",
    //                   //     fontSize: "12px",
    //                   //     "& .MuiDataGrid-columnHeaders": {
    //                   //       backgroundColor: "#F4F8FA !important",
    //                   //       color: "#42526D",
    //                   //       textAlign: "center",
    //                   //       fontSize: "14px",
    //                   //     },
    //                   //     "& .MuiDataGrid-virtualScroller":{
    //                   //         height:" calc(-16rem + 100vh)",
    //                   //       overflow:"auto !important",
    //                   //     },

    //                   //     "& .MuiDataGrid-columnHeader": {
    //                   //       backgroundColor: "#F4F8FA !important",
    //                   //       textAlign: "center",
    //                   //     },
    //                   //     "& .MuiDataGrid-columnHeaderTitle": {
    //                   //       color: "#42526D",
    //                   //       fontWeight: "500",
    //                   //       textAlign: "center",
    //                   //       fontFamily: "work sans",
    //                   //     },
    //                   //     "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
    //                   //       {
    //                   //         backgroundColor: "#F4F8FA !important",
    //                   //         color: "#42526D !important",
    //                   //         borderColor: "#D3D9E1 !important", //light grey
    //                   //         // textAlign: "center",
    //                   //       },
    //                   //     "& .MuiDataGrid-cell": {
    //                   //       display: "flex",
    //                   //       justifyContent: "center",
    //                   //       alignItems: "center",
    //                   //       // textAlign: "center",
    //                   //       color: "#42526D",
    //                   //       fontFamily: "work sans",
    //                   //     },
    //                   //     "& .MuiTablePagination-root p": {
    //                   //       marginBottom: 0,
    //                   //     },
    //                   //   }}
    //                   // />
    //                   // <DataGridTable columns={columns} rows={currentItems} rowsPerPageOptions={[100]} pageSize={100}
    //                   //  applystyle={true}

    //                   //  localeText={{
    //                   //   MuiTablePagination: {
    //                   //     labelRowsPerPage: 'Rows per page:',
    //                   //   },
    //                   // }}
    //                   // />
    //                   <DataGridTable
    //                     columns={columns}
    //                     rows={currentItems}
    //                     // rowsPerPageOptions={ [2, 5, 10]}
    //                     // pageSize={ 5}
    //                     // showBorder={true}
    //                      rowHighlight={true}
    //                     rowsPerPageOptions={[5,10,25,100]}
    //                     pageSize={100}
    //                     showBorder={true}

    //                   />
    //                 ) : (
    //                   "No Data Found"
    //                 )}

    //                 {/* { React Paginate Component */}
    //                 {/* <div className="pagination-container">
    //         <ReactPaginate
    //           previousLabel={"←"}
    //           nextLabel={"→"}
    //           breakLabel={"..."}
    //           pageCount={pageCount}
    //           marginPagesDisplayed={2}
    //           pageRangeDisplayed={3}
    //           onPageChange={handlePageClick}
    //           containerClassName={"pagination"}
    //           activeClassName={"active"}
    //           pageLinkClassName={"page-link"}
    //           previousLinkClassName={"page-link"}
    //           nextLinkClassName={"page-link"}
    //           breakLinkClassName={"page-link"}
    //         />
    //         </div> */}
    //               </Box>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     </div>

    //     <Footer />
    //     {isCandidateModalOpen && (
    //       <CreateCandidateForm
    //         onClose={handleCloseModal}
    //         onSubmit={handleFormSubmit}
    //       />
    //     )}
    //     {isGenerateLinkModalOpen && (
    //       <GenerateLinkModal
    //         open={true}
    //         onClose={handleCloseModal}
    //         url={generatedLink}
    //         name={candidateName}
    //       />
    //     )}
    //   </div>
    <div className="flex flex-col h-screen">
      <div className="fixed top-0 left-0 right-0 z-1 shadow-md">
        <Navbar />
      </div>
      <div className="flex flex-grow">
        <div
          className="bg-[#7A8286] w-[12%] sticky top-[49px] ]"
          style={{ height: "95vh" }}
        >
          <Sidebar />
        </div>
        <div className="w-[88%]  overflow-hidden">
          <div className="flex flex-col h-full  top-[48px] relative">
            <div className="flex-grow top-12 bg-white   p-4 shadow-md">
              <div className="flex justify-between items-center">
                <div className="flex w-2/5 items-center">
                  <div className="text-lg font-semibold text-[#6491A9] mr-6">
                    Candidates
                  </div>
                  <div className="flex items-center border border-gray-300 rounded-xl px-2 py-1 bg-[#EBF2F6] w-2/3">
                    <SearchIcon className="text-gray-500 mr-2" />
                    <InputBase
                      placeholder="Search a name, email and phone number"
                      className="flex-1 text-xs focus:outline-none"
                      sx={{
                         fontSize: '14px', 
                      }}
                    />
                  </div>
                </div>
                {/* <button
                  onClick={handleOpenCandidateModal}
                  className="bg-[#6491A9] rounded-lg text-[13px] text-white px-4 py-2"
                >
                  Add Candidate
                </button> */}
                <CustomButton label="Add Candidate" onClick={handleOpenCandidateModal} />
              </div>
            </div>
            <div className="relative overflow-auto p-4 bg-[#F4F8FA] h-full">
              {loading ? (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                  <ClipLoader color="#6491A9" loading={loading} size={50} />
                </div>
              ) : (
                <Box sx={{ width: "100%",}}>
                  {candidateList && candidateList.length > 0 ? (
                    <div className="relative h-full">
                      <DataGridTable
                        columns={columns}
                        rows={currentItems}
                        pagesizeoption={[5, 10, 25, 100]}
                        pageSize={25}
                        showBorder={true}
                        rowHighlight={true}
                        // sx={{
                        //   '& .MuiDataGrid-columnHeaders': {
                        //     position: 'sticky',
                        //     top: 0,
                        //     zIndex: 10,
                        //     backgroundColor: '#F4F8FA', // Match the background color for seamless appearance
                        //   },
                        // }}
                      />
                    </div>
                  ) : (
                    "No Data Found"
                  )}
                </Box>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      {isCandidateModalOpen && (
        <CreateCandidateForm
          onClose={handleCloseModal}
          onSubmit={handleFormSubmit}
        />
      )}
      {isGenerateLinkModalOpen && (
        <GenerateLinkModal
          open={true}
          onClose={handleCloseModal}
          url={generatedLink}
          name={candidateName}
        />
      )}
    </div>
  );
}

export default Candidates;
