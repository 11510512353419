import React from 'react';
import MuiButton from '@mui/material/Button'; 

export default function CustomButton(props) {
  return (
    <div>
      <MuiButton
        type={props.type}
        onClick={props.onClick}
        className={props.className}
        sx={{
          backgroundColor: '#6491A9',
          color: 'white',
          padding:'0.5rem 1rem',
          borderRadius:'0.5rem',
          fontSize:'13px',
          fontWeight:'500',
          height:'37px',
          fontFamily: 'Work Sans',
          textTransform:'capitalize',
          '&:hover': {
            backgroundColor: '#507889', // Optional: Hover background color
          },
        }}
      >
        {props.label}
      </MuiButton>
    </div>
  );
}

