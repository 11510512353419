import React, { useEffect, useState, useRef } from "react";
// import { CircularProgress, Button } from "@mui/material";
import { Gauge } from "@mui/x-charts/Gauge";
import apiService from "../../service";
import "./Chats.css";
import moment from "moment";
import BeatLoader from "react-spinners/BeatLoader";

function Modal({ content, onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header flex justify-between bg-[#D0DDE4] p-2 items-center">
          <div className="flex">
            <img src="/Images/info.png" className="mr-2" alt="doc" />
            {content.title}
          </div>
          <button className="close-button mr-3" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          <div dangerouslySetInnerHTML={{ __html: content.jobDescription }} />
        </div>
      </div>
    </div>
  );
}

function formatElapsedTime(createdAt) {
  // console.log(`Timestamp received: ${createdAt}`); // Log the received timestamp

  const now = moment();
  const messageTime = moment.utc(createdAt);

  if (!messageTime.isValid()) {
    console.error("Invalid date:", createdAt); // Log invalid dates
    return "Invalid date";
  }

  const duration = moment.duration(now.diff(messageTime));

  if (duration.asMinutes() < 1) {
    return "Just now";
  } else if (duration.asHours() < 1) {
    return `${Math.floor(duration.asMinutes())} minutes ago`;
  } else if (duration.asDays() < 1) {
    return `${Math.floor(duration.asHours())} hours ago`;
  } else if (duration.asDays() < 30) {
    return `${Math.floor(duration.asDays())} days ago`;
  } else if (duration.asMonths() < 12) {
    return `${Math.floor(duration.asMonths())} months ago`;
  } else {
    return `${Math.floor(duration.asYears())} years ago`;
  }
}

function ChatWindow(score) {
  //   const array = [10, 20, 30, 50, 80];
  console.log(score);
  const [individual, setIndividual] = useState([]);
  const [chats, setChats] = useState([]);
  const [text, setText] = useState("");
  // const [candidateInfo] = useState(score.score.candidateInfo);
  const [jobId] = useState(score.score.jd_id);
  // const [uniqueId] = useState(score.score.uniqueId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const containerRef = useRef(null);
  const [token] = useState(score.score.token);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const matchDetails = score?.score?.match_details ?? score?.score?.individual_match_scores ?? {}; //if value not found it should be empty objeect
    console.log('Score:', matchDetails);
    const skillArray = Object.entries(
      matchDetails
    ).map(([label, value]) => ({
      label,
      value,
    }));
    // console.loog(skillArray)
    setIndividual(skillArray);
  }, [score]);

  const handleChat = async () => {
    const obj = {
      type: "user",
      message: text,
    };

    setChats((prevChats) => [...prevChats, obj]);
    setText("");
    setIsLoading(true);
    // console.log(obj);
    const result = await apiService.chatPdf(token, text);
    // console.log(result);
    if (result.message) {
      const obj = {
        type: "bot",
        message: result.message,
      };
      setIsLoading(false);
      setChats((prevChats) => [...prevChats, obj]);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleChat();
    }
  };
  useEffect(() => {
    const getJD = async () => {
      // console.log(jobId);
      const result = await apiService.getJdInfo(jobId);
      // console.log(result);
      if (result && result.message && result.message === "Succesfull") {
        const rawContent = result.data.jobDescription;
        result.data.jobDescription = rawContent.replace(/\n/g, "<br />");
        setModalContent(result.data);
        // setIsModalOpen(true);
      }
    };
    console.log(modalContent);
    getJD();

    const getchatHistoryConversations = async () => {
      try {
        const results = await apiService.getChatHistory(token);
        console.log(results);

        if (results && results.data) {
          // Map the `text` field to `message`
          const transformedData = results.data.map((item) => ({
            id: item._id,
            type: item.type,
            channelId: item.channel_id,
            message: item.text, // Mapping `text` to `message`
            createdAt: item.created_at,
          }));

          setChats(transformedData);
        } else {
          console.warn("No data found in results:", results);
        }
      } catch (error) {
        console.error("Error fetching chat history conversations:", error);
        // You can also show a user-friendly message here or handle the error in another way.
      }
    };

    getchatHistoryConversations();
  }, [jobId]);

  //Loading scroll
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chats, isLoading]); 

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  };

  return (
    <div className="h-[86vh] pl-3 ">
      <div className="chat-container pt-0">
        <div className="chat-header h-12">
          <div className="flex items-center">
            <div>
              <img src="/Images/favicon_career.svg" alt="Logo" />
            </div>
            <div className="ml-3">Matching score based on your JD</div>
          </div>
          <div
            className="flex items-center cursor-pointer"
            onClick={handleOpenModal}
          >
            <div className="text-[#999]">{modalContent.title}</div>
            <div>
              <img src="/Images/info.png" alt="info" className="ml-2" />
            </div>
          </div>
        </div>
        <div className="chat-window">
          <div className="chat-body" ref={containerRef}>
            <div
              style={{ width: "100%", color: "#5C6568" }}
              className="flex p-3 mt-1 font-normal"
            >
              <div
                style={{
                  display: "flex",
                  background: "#E2F6FC",
                  padding: "20px",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className="col-md-4 rounded-l-lg"
              >
                {score?.score?.score.overall_percentage_match && (
                  <>
                    <Gauge
                      width={120}
                      height={120}
                      value={score?.score?.score.overall_percentage_match}
                    />
                    <p>Match Rate</p>
                  </>
                )}
              </div>
              <div
                style={{ background: "#CFF3FB", padding: "20px" }}
                className="col-md-8 rounded-r-lg"
              >
                <div className="row">
                  {individual.map((item, index) => (
                    <div
                      style={{
                        display: "flex",
                        textAlign: "start",
                        marginTop: "10px",
                      }}
                      className="col-md-6"
                      key={index}
                    >
                      <p style={{ marginBottom: "0px", marginRight: "5px" }}>
                        {item.value}
                      </p>{" "}
                      <p style={{ margin: "0px" }}>{item.label}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="chat-container">
              <div className="chat-box">
                {chats.map((chat, index) => (
                  <div key={index} className={`chat-row ${chat.type}`}>
                    {chat.type === "bot" && (
                      <img
                        src="/Images/favicon_career.svg" // replace with the actual path to the avatar image
                        alt="Bot Avatar"
                        className="bot-avatar"
                      />
                    )}
                    {chat.type === "user" && (
                      <div className="w-7 h-7 mr-2 bg-[#6491A9] text-white rounded-full flex items-center justify-center font-bold">
                        {/* {score.score?.candidateInfo.firstName.charAt(0)} */}
                        A
                      </div>
                    )}
                    <div className={`chat-message ${chat.type}`}>
                      {chat.type === "user" && (
                        <div className="timestamp">
                          {formatElapsedTime(chat.createdAt)}
                        </div>
                      )}
                      {chat.message}
                    </div>
                  </div>
                ))}
                {isLoading && (
              <div className="chat-row bot">
                <div className="chat-message bot">
                  <BeatLoader size={10} color={"black"} background ={""} loading={isLoading} />
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
              </div>
            </div>
          </div>
          <div className="chat-scroller">
            <button
              onClick={scrollToTop}
              className="p-2 rounded-md shadow-lg focus:outline-none shadow-gray-200"
              aria-label="Scroll to top"
            >
              <img src="/Images/arrow-up-02.png" alt="up" />
            </button>
          </div>
          <div className="chat-footer">
            <div className="flex items-center border rounded-lg overflow-hidden w-full">
              <input
                type="text"
                className="p-2 w-full focus:outline-none"
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
                onKeyDown={handleKeyPress}
                placeholder="Message here..."
              />
              <button
                className=" p-2 flex items-center justify-center"
                disabled={!text}
                onClick={handleChat}
              >
                {!text && <img src="/Images/send.png" alt="send" />}
                {text && <img src="/Images/send_text.png" alt="send" />}
              </button>
            </div>
          </div>
          <div className="chat-footer-declaration">
            <span>
              Career Connect is AI generated and can make mistakes. Check
              important info
            </span>
          </div>
        </div>

        {isModalOpen && (
          <Modal content={modalContent} onClose={handleCloseModal} />
        )}
      </div>
    </div>
  );
}

export default ChatWindow;
